import { render, staticRenderFns } from "./QuoteViewItem.vue?vue&type=template&id=563a83ed&scoped=true&"
import script from "./QuoteViewItem.vue?vue&type=script&lang=ts&"
export * from "./QuoteViewItem.vue?vue&type=script&lang=ts&"
import style0 from "./QuoteViewItem.vue?vue&type=style&index=0&id=563a83ed&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563a83ed",
  null
  
)

export default component.exports